import ReactFullpage, { Item } from '@fullpage/react-fullpage';
import React from 'react';

import JuniperNavbar from './components/JuniperNavbar';
import licenses from './config/fullpage.json';
import HomePage from './pages/Home';
import RSVP from './pages/RSVP';
import Details from './pages/Details';

function App() {
    const [activeSection, setActiveSection] = React.useState<string | number>(
        'home',
    );

    return (
        <>
            <div className="header">
                <JuniperNavbar
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                />
            </div>
            <ReactFullpage
                {...licenses.fullPage}
                afterLoad={(_: Item, destination: Item) => {
                    setActiveSection(destination.anchor);
                }}
                render={({ fullpageApi }) => {
                    window.fullpageApi = fullpageApi;

                    return (
                        <ReactFullpage.Wrapper>
                            <HomePage></HomePage>
                            <Details></Details>
                            <RSVP></RSVP>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </>
    );
}

export default App;
