import React from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';

import styles from './JuniperNavbar.module.scss';

interface JuniperNavbarProps {
    activeSection: string | number;
    setActiveSection: (section: string) => void;
}

const JuniperNavbar: React.FunctionComponent<JuniperNavbarProps> = ({
    activeSection,
    setActiveSection,
}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleLinkClick = (activeSection: string) => {
        setExpanded(false);
        setActiveSection(activeSection);
        window.fullpageApi.moveTo(activeSection);
    };

    return (
        <header className={styles.container}>
            <Navbar
                className={styles.navbar}
                bg="dark"
                data-bs-theme="dark"
                fixed="top"
                expand="lg"
                expanded={expanded}
            >
                <Container fluid>
                    <Navbar.Toggle
                        onClick={() => setExpanded(!expanded)}
                    ></Navbar.Toggle>
                    <Navbar.Offcanvas
                        placement="start"
                        scroll
                        bg="dark"
                        data-bs-theme="dark"
                    >
                        <Offcanvas.Header closeButton></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className={styles.navLinks}>
                                <Nav.Link
                                    onClick={() => handleLinkClick('home')}
                                    active={activeSection === 'home'}
                                >
                                    Home
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => handleLinkClick('story')}
                                    active={activeSection === 'story'}
                                >
                                    Our Story
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => handleLinkClick('details')}
                                    active={activeSection === 'details'}
                                >
                                    Wedding Details
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() =>
                                        handleLinkClick('accommodations')
                                    }
                                    active={activeSection === 'accommodations'}
                                >
                                    Accommodations
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => handleLinkClick('rsvp')}
                                    active={activeSection === 'rsvp'}
                                >
                                    RSVP
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => handleLinkClick('qa')}
                                    active={activeSection === 'qa'}
                                >
                                    Q & A
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    );
};
export default JuniperNavbar;
