import Section from '@components/Section';
import React from 'react';

const RSVP = () => {
    return (
        <Section header="RSVP">
                <iframe
                    src="/rsvp"
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="RSVP Form"
                ></iframe>
        </Section>
    );
};
export default RSVP;
