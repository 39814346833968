import React from 'react';

import styles from './Details.module.scss';
import Section from '../../components/Section';

const details: React.FunctionComponent = () => {
    return (
        <Section>
            <div className={styles.details}>
                <div>Our Story</div>
                <div>Wedding Details</div>
                <div>Accommodations</div>
                <div>Q & A</div>
            </div>
        </Section>
    );
};
export default details;
