import React from 'react';

import styles from './Section.module.scss';

interface SectionProps {
    header?: string;
    children: React.ReactNode;
}

const Section: React.FunctionComponent<SectionProps> = ({
    header,
    children,
}) => {
    return (
        <div className="section no-padding">
            <div className={styles.wrapper}>
                <div className={styles.contents}>
                    {header && <h2 className={styles.header}>{header}</h2>}
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
};
export default Section;
